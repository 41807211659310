import type {
  FetchCustomerPayload,
  FetchCustomersPayload,
  CreateCustomerPayload,
  UpdateCustomerPayload,
  DeleteCustomerPayload,
} from "./customer.api.types"
import { authAxios, type AxiosResponseData } from "@/utils/axios"

const customerApi = {
  fetchCustomers: (payload: FetchCustomersPayload) => {
    return authAxios.get<AxiosResponseData>("customers", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    })
  },
  fetchCustomer: (payload: FetchCustomerPayload) => {
    const { id, ...params } = payload.params
    return authAxios.get<AxiosResponseData>(`customers/${id}`, {
      params,
      cancelToken: payload.cancelToken,
    })
  },
  createCustomer: (payload: CreateCustomerPayload) => {
    return authAxios.post<AxiosResponseData>(`customers`, payload.params, {
      cancelToken: payload.cancelToken,
    })
  },
  updateCustomer: (payload: UpdateCustomerPayload) => {
    const { id, ...params } = payload.params
    return authAxios.put<AxiosResponseData>(`customers/${id}`, params, {
      cancelToken: payload.cancelToken,
    })
  },
  deleteCustomer: (payload: DeleteCustomerPayload) => {
    const { id } = payload.params
    return authAxios.delete<AxiosResponseData>(`customers/${id}`, {
      cancelToken: payload.cancelToken,
    })
  },
}

export default customerApi
