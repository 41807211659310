export const genderTitleMap = {
  MR: "Male",
  MRS: "Male",
  MS: "Female",
}

export enum CustomerGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum CustomerRespectTitle {
  MR = "MR",
  MRS = "MRS",
  MS = "MS",
}

export const customerGenders = [
  {
    label: "Male",
    value: CustomerGender.MALE,
  },
  {
    label: "Female",
    value: CustomerGender.FEMALE,
  },
]

export const customerGenderMap = customerGenders.reduce(
  (item, customerGender) => {
    item[customerGender.value] = customerGender
    return item
  },
  {} as Record<string, (typeof customerGenders)[number]>,
)

export const respectTitles = [
  {
    label: "mr",
    value: CustomerRespectTitle.MR,
  },
  {
    label: "mrs",
    value: CustomerRespectTitle.MRS,
  },
  {
    label: "ms",
    value: CustomerRespectTitle.MS,
  },
]

export const respectTitleMap = respectTitles.reduce((item, respectTitle) => {
  item[respectTitle.value] = respectTitle
  return item
}, {} as Record<string, (typeof respectTitles)[number]>)
