import { all, call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { shopApi } from "@/utils/apis"

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action"
import { ShopActionTypes } from "./types"

import type { FetchShopsSagaAction, FetchShopSagaAction } from "./types"

function* fetchShops(action: FetchShopsSagaAction) {
  const { params, cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "shops",
    }),
  )

  try {
    const { data: response }: Awaited<ReturnType<typeof shopApi.fetchShops>> =
      yield call(shopApi.fetchShops, {
        params,
        cancelToken,
      })

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "shops",
          data: response.data.list,
          count: response.data.count,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "shops",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "shops",
        error: message,
      }),
    )
  }
}

function* fetchShop(action: FetchShopSagaAction) {
  const { params, cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "shop",
    }),
  )

  try {
    const { data: response }: Awaited<ReturnType<typeof shopApi.fetchShop>> =
      yield call(shopApi.fetchShop, {
        params,
        cancelToken,
      })

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "shop",
          data: response.data,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "shop",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "shop",
        error: message,
      }),
    )
  }
}

function* shopSaga() {
  yield all([
    takeEvery(ShopActionTypes.FETCH_SHOPS_SAGA, fetchShops),
    takeEvery(ShopActionTypes.FETCH_SHOP_SAGA, fetchShop),
  ])
}

export default shopSaga
