import { ShopActionTypes } from "./types"
import type { ShopState, ShopAction } from "./types"
import { commonHelpers } from "@/utils/helpers"

export const initialState: ShopState = {
  shops: [],
  shopsCount: 0,
  shopsError: "",
  shopsLoading: false,

  shop: null,
  shopError: "",
  shopLoading: false,
}

const reducer = (state = initialState, action: ShopAction) => {
  switch (action.type) {
    case ShopActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      }
    }
    case ShopActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        [`${scope}Error`]: "",
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      }
    }
    case ShopActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload

      return {
        ...state,
        [`${scope}Error`]: error,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
