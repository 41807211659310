import { authAxios, AxiosResponseData } from "@/utils/axios"
import {
  FetchShopsPayload,
  FetchShopPayload,
  CreateShopPayload,
  UpdateShopPayload,
  DeleteShopPayload,
} from "./shop.api.types"

const shopApi = {
  fetchShops: (payload: FetchShopsPayload) => {
    return authAxios.get<AxiosResponseData>("shops", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    })
  },
  fetchShop: (payload: FetchShopPayload) => {
    const { id } = payload.params
    return authAxios.get<AxiosResponseData>(`shops/${id}`, {
      cancelToken: payload.cancelToken,
    })
  },
  createShop: (payload: CreateShopPayload) => {
    return authAxios.post<AxiosResponseData>(`shops`, payload.params, {
      cancelToken: payload.cancelToken,
    })
  },
  updateShop: (payload: UpdateShopPayload) => {
    const { id, ...params } = payload.params
    return authAxios.put<AxiosResponseData>(`shops/${id}`, params, {
      cancelToken: payload.cancelToken,
    })
  },
  deleteShop: (payload: DeleteShopPayload) => {
    const { id } = payload.params
    return authAxios.delete<AxiosResponseData>(`shops/${id}`, {
      cancelToken: payload.cancelToken,
    })
  },
}

export default shopApi
