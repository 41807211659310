// Action Types
import type {
  FetchShopsPayload,
  FetchShopPayload,
  FetchShopsResponseData,
  FetchShopResponseData,
} from "@/utils/apis/shop/shop.api.types"

export enum ShopActionTypes {
  FETCH_REQUESTED = "@@shop/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@shop/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@shop/FETCH_FAILED",
  SET_SHOPS_FIELD_VALUES = "@@shop/SET_SHOPS_FIELD_VALUES",

  // Saga
  FETCH_SHOPS_SAGA = "@@shop/FETCH_SHOPS_SAGA",
  FETCH_SHOP_SAGA = "@@shop/FETCH_SHOP_SAGA",
}

// State

export interface ShopState {
  shops: FetchShopsResponseData["list"]
  shopsCount: number
  shopsLoading: boolean
  shopsError: string

  shop: FetchShopResponseData | null
  shopLoading: boolean
  shopError: string
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: ShopActionTypes.FETCH_REQUESTED
  payload: {
    scope: "shop" | "shops"
  }
}

export type FetchSucceededAction = {
  type: ShopActionTypes.FETCH_SUCCEEDED
  payload: {
    scope: "shop" | "shops"
    data: ShopState[FetchSucceededAction["payload"]["scope"]]
    count?: number
  }
}

export type FetchFailedAction = {
  type: ShopActionTypes.FETCH_FAILED
  payload: {
    scope: "shop" | "shops"
    error: string
  }
}

// ---- Saga ----

export type FetchShopsSagaAction = {
  type: ShopActionTypes.FETCH_SHOPS_SAGA
  payload: FetchShopsPayload
}

export type FetchShopSagaAction = {
  type: ShopActionTypes.FETCH_SHOP_SAGA
  payload: FetchShopPayload
}

export type ShopAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchShopsSagaAction
  | FetchShopSagaAction
